import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../components/image"
import { FaChevronRight } from "react-icons/fa"
import Helmet from "react-helmet"
import ListTop3 from "../components/listTop3"
import { XIcon} from "react-share"
import {ImageFilename} from '../utils/common'

const typeSizePage = ({ data, location, pageContext: { type_id, size_id } }) => {
	const type = data.typesYaml
	const size = data.sizesYaml
	const sizeStr = ((type.cid !== 'wipe') && (type.cid !== 'handwipe'))? 'サイズ' : 'タイプ' 
	const itemStr = ((type.cid !== 'wipe') && (type.cid !== 'handwipe'))? 'オムツ' : type.description
	let description = `${type.description} ${size.description}${sizeStr} ${itemStr}1枚あたりの最安値！`
	let preTitle = ``

	// allContentfulItemsを1枚当たりの価格が安い順に並び替える(Graphqlのソートだと100円以上の場合に並び替えが正しくできないため数値のソートを自前で行う)
	data.allContentfulItems.edges.sort(function(a,b){
		if((!a.node.itemList.length) && (!b.node.itemList.length)){
			return 0
		}
		// itemList無ければ後ろへ並べる
		else if((!a.node.itemList.length) && (b.node.itemList.length)){
			return 1
		}
		else if((a.node.itemList.length) && (!b.node.itemList.length)){
			return -1
		}
		return a.node.itemList[0].per - b.node.itemList[0].per
	})	

	// allBrandsYamlを1枚当たりの価格が安い順に並び替える
	let orderAllBrandsYaml = data.allBrandsYaml.edges.map(edge => {
		const item = data.allContentfulItems.edges.find(n => {
			return n.node.typeId === type.cid && n.node.sizeId === size.cid && n.node.brandId === edge.node.cid
		})
		if(item) {
			if(item.node.itemList.length){
				edge['per'] = item.node.itemList[0].per	
			}
		}
		return edge
	}).sort(function(a,b){
		return a.per - b.per
	})

	let cnt = 0

	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": type.description,
			"item": "https://hula-la.com/"+type.cid+".html"
		},{
			"@type": "ListItem",
			"position": 3,
			"name": size.description,
			"item": "https://hula-la.com"+location.pathname
		}]
	};

	return (
  <Layout>
		<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>
			<ul>
				<li><Link to="/">トップ</Link></li>
				<li><Link to={`/${type.cid}.html`}>{type.description}</Link></li>
				<li className="is-active"><Link to="" aria-current="page">{size.description}</Link></li>
			</ul>
		</nav>
			<>
				<Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
   			</Helmet>
				<h2 id="top10" className="title is-6" style={{marginTop: `0.2rem`, marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{type.description} {size.description}{sizeStr} Top10</h2>
				<p style={{fontSize:`0.8rem`, color: `#808080`}}><small>&nbsp;&nbsp;&nbsp;<strong>割引後にポイントと送料を含めた実質価格で</strong>1枚あたりを計算！</small></p>
				<p style={{fontSize:`0.8rem`, color: `#808080`, marginBottom: `0.5rem`}}><small>&nbsp;&nbsp;&nbsp;（本ページのリンクには広告が含まれています）</small></p>
        <div class="container"style={{marginRight: `0.0rem`}}>
          <div class="columns is-mobile">
            <div class="column is-9" style={{paddingBottom: `0.9rem`}}>
              <div className="has-text-centered" style={{paddingTop: `0.3rem`, paddingBottom: `0.0rem`}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <a href="https://x.com/hula_la_" target='_blank'><XIcon size={18} round /></a>
                <p style={{fontSize:`0.7rem`, marginTop: `-0.3rem`}}><a href="https://x.com/hula_la_" target='_blank' style={{color: `#0783c2`}}>&nbsp;&nbsp;&nbsp;\\ X(Twitter)で更新ポスト //</a></p>
                </div>
              </div>              
            </div>
            <div class="column is-3" style={{paddingBottom: `0.9rem`}}>
            </div>
          </div>
        </div>
				<ListTop3 typeValue={type.cid} sizeValue={size.cid} brandValue="all" shopValue="all" articleUrl={`https://hula-la.com/${type.cid}_${size.cid}.html`} articleTitle={`${type.description} ${size.description}${sizeStr}`} />
				<h3 id="brand" className="title is-6" style={{marginTop: `0.2rem`, marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>ブランド別</h3>
				<div className="columns is-multiline is-gapless">
					{orderAllBrandsYaml.map(edge => {
						const brand = edge.node
						const item = data.allContentfulItems.edges.find(n => {
							return n.node.brandId === brand.cid
						})
						if(!item) return <></>
						if(!item.node.itemList.length) return <></>
						const filename = ImageFilename(brand.cid, type.cid, size.cid, item.node.subbrandId, item.node.itemList[0])
						description += `${brand.description}${item.node.itemList[0].per.toFixed(1)}円 `
						preTitle += preTitle === `` ? `${item.node.itemList[0].per.toFixed(1)}円/枚` : ``
						let most_min = 100
						let most_max = 0
						let most_unit = ''
						data.allSubbrandsYaml.edges.forEach(n => {
							if (n.node.brand_id === brand.cid) {
								n.node.types.forEach(m => {
									if(m.type_id === type.cid) {
										m.sizes.forEach(l => {
											if(l.size_id === size.cid) {
												most_min = l.min < most_min ? l.min : most_min
												most_max = l.max > most_max ? l.max : most_max
												most_unit = l.unit
											}
										})
									}
								})
							}
						})
						const min = most_min !== 0 ? `${most_min}${most_unit}` : ``
						const max = `${most_max}${most_unit}`
						let min_max = min + '～' + max
						if((most_min === 0) && (most_max === 0))
						{
							min_max = '-'
						}
						cnt += 1
						const order = cnt
						return (
							<div className="column is-half">
								<Link to={`/${brand.cid}_${type.cid}_${size.cid}.html`}>
									<div className="card">
										<div className="card-content" style={{padding: `0.8rem`}}>
											<div className="media">
												<div className="media-left">
													<div>
														<span>{order}</span><span style={{fontSize:`0.5rem`}}>位</span>
													</div>
													<Image filename={filename} alt={`${brand.description}${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
												</div>
												<div className="media-content">
													<div className="content">
														<p>
															<span style={{fontSize:`0.7rem`}}><strong>{brand.description}</strong></span>
															<br />
															<span style={{color: `#ff0000`}} className="is-size-4">&nbsp;{item.node.itemList[0].per.toFixed(1)}<span style={{fontSize:`0.7rem`}}> 円/枚</span> ～</span>
															<br />
															<span className="tag" style={{fontSize:`0.6rem`}}>{type.description}</span>
															{ min_max !== '-' && (
																<span className="tag" style={{fontSize:`0.6rem`}}>{min_max}</span>
															)}
														</p>
													</div>
												</div>
												<div className="media-right">
													<span className="icon" style={{height: `2.5em`}}>
														<FaChevronRight />
													</span>
												</div>
											</div>
										</div>
									</div>
								</Link>
							</div>
						)
					})}
				</div>
			</>
		<Seo title={`${type.description} ${size.description}`} description={description} canonical={location.pathname} />
  </Layout>
	)
}

export const query = graphql`
	query($type_id: String! $size_id: String!) {
		typesYaml(cid: { eq: $type_id}) {
			cid
			description
		}
		allTypesYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		sizesYaml(cid: { eq: $size_id}) {
			cid
			description
		}
		allBrandsYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allContentfulItems(filter: {typeId: { eq: $type_id} sizeId: { eq: $size_id}}) {
			edges {
				node {
					brandId
					typeId
					sizeId
					subbrandId
					itemList {
						num
						shopId
						shop
						href
						per
						per_org
						price
						price_org
						sendPrice
						souryou
						txt
						point
						star
						review
						reviewUrl
					}
					updatedAt
				}
			}
		}
		allSubbrandsYaml {
			edges {
				node {
					brand_id
					subbrand_id
					description
					types {
						type_id 
						sizes {
							size_id
							min
							max
							unit
							num	
						}
					}
				}
			}
		}
	}
`

export default typeSizePage
